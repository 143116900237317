import { observable, action } from 'mobx'
import { getState, setState, clearState } from './../services/localStorage'
import { loginApi, signupApi, forgetPasswordApi } from './../services/auth'
import { routes, history } from './../config/routes'
import { errorToast, successToast } from './../utils'
import { createContext } from 'react'

export const initialState = {
  /* data key stays in sync with localStorage so store the only values which you want to persist */
  userData: null,
  token: null,
  ui: {
    isSignInLoading: false,
    isSignUpLoading: false,
    isForgetPasswordLoading: false,
  },
}

class authStore {
  @observable state

  constructor() {
    const token = getState('token')
    const userData = getState('userData')
    const state = {
      token,
      userData,
      ui: {
        isSignInLoading: false,
        isSignUpLoading: false,
        isForgetPasswordLoading: false,
      },
    }
    this.setState(state)
  }

  redirectedOrNot = () => {
    // const {
    //     state: { userData, token },
    // } = this
    const token = getState('token')
    const userData = getState('userData')
    return userData && token
  }

  login = async (loginData) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isSignInLoading: true } })
    try {
      if (loginData?.email && loginData?.password) {
        const response = await loginApi(loginData)
        const { data, token } = response?.data

        loginData.token = token
        loginData.userData = data?.currentUserDetails
      }

      loginData.token && setState('token', loginData.token)
      loginData.userData && setState('userData', loginData.userData)
      loginData.token &&
        history.push({
          pathname: routes.teamlist.path,
          state: {
            token: loginData.token,
            userData: loginData.userData,
          },
        })
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isSignInLoading: false } })
  }

  setIsFrontendUser = (location) => {
    if (location) {
      if (location.split('&').length === 4) {
        setState('is_front_end_user', 1)
        localStorage.setItem('is_front_end_user', 1)
      }
    }
  }

  forgetPassword = async (forgetPasswordData) => {
    const { ui } = this.state
    this.setState({ ui: { ...ui, isForgetPasswordLoading: true } })
    try {
      const response = await forgetPasswordApi(forgetPasswordData)
      const { message, status } = response
      if (status) {
        successToast(message)
      } else {
        errorToast(message)
      }
    } catch (error) {
      const { message } = error
      errorToast(message)
    }
    this.setState({ ui: { ...ui, isForgetPasswordLoading: false } })
  }

  @action
  setState = (params) => {
    const { state } = this
    this.state = {
      ...state,
      ...params,
    }
    this.state?.userData && setState('userData', this.state.userData)
    this.state?.userData?.token && setState('token', this.state.userData.token)
  }

  @action
  reset = () => {
    this.state = initialState
    clearState()
  }
}

export const AuthStore = createContext(new authStore())
