import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import { updateMetadata } from './../utils'

export const StaticRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { isStatic, pageName } = rest
      updateMetadata(pageName)

      if (isStatic) {
        return (
          <Fragment>
            <Component {...props} />
          </Fragment>
        )
      } else {
        return <Component {...props} isAuthenticated={false} />
      }
    }}
  />
)
