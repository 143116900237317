import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routes as routePaths } from './../config/routes'
import { updateMetadata } from './../utils'
import { AuthStore } from './../stores/AuthStore'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { redirectedOrNot } = useContext(AuthStore)
  return (
    <Route
      {...rest}
      render={(props) => {
        const { pageName } = rest
        const resp = redirectedOrNot()
        // console.log('resp', resp)

        updateMetadata(pageName)

        if (resp) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: routePaths.login.path,
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}
