import React, { Suspense } from 'react'
import { observer } from 'mobx-react'
import Routes from './routes'
import Loader from './../components/Loader/Loader'
import './../App.css'
import 'antd/dist/antd.css'
import './../common.scss'

const App = (props) => {
  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Routes />
      </Suspense>
    </div>
  )
}

export default observer(App)
