export const getState = (key) => {
  try {
    const serializedState =
      localStorage.getItem(key) || sessionStorage.getItem(key)
    if (!serializedState) {
      return undefined
    }
    return key === 'token' ? serializedState : JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const setState = (key, data) => {
  try {
    const serializedState = key === 'token' ? data : JSON.stringify(data)
    if (localStorage.getItem('checked')) {
      sessionStorage.clear()
      localStorage.setItem(key, serializedState)
    } else {
      localStorage.clear()
      sessionStorage.setItem(key, serializedState)
    }
  } catch {
    // ignore write errors
  }
}

export const removeState = (key) => {
  try {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  } catch {
    // ignore write errors
  }
}

export const clearState = () => {
  localStorage.clear()
  sessionStorage.clear()
}
