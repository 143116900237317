import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routes as routePaths } from './../config/routes'
import { updateMetadata } from './../utils'
import { AuthStore } from './../stores/AuthStore'

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { redirectedOrNot, setIsFrontendUser } = useContext(AuthStore)

  return (
    <Route
      {...rest}
      render={(props) => {
        const { pageName } = rest
        const resp = redirectedOrNot()
        updateMetadata(pageName)
        setIsFrontendUser(props.location.search)
        //console.log('resp public', resp)
        if (!resp) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: routePaths.dashboard.path,
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}
