import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App/index'
import * as serviceWorker from './serviceWorker'

import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <Fragment>
    <App />
    <ToastContainer autoClose={2000} position={'top-right'} />
  </Fragment>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
