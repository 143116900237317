import { lazy } from 'react'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const routes = {
  dashboard: {
    name: 'PreSeason Planner',
    path: '/',
    component: lazy(() => import('../components/Teamlist/Teamlist')),
    exact: true,
    public: false,
    private: true,
  },
  teamdetail: {
    name: 'Team Detail',
    path: '/dashboard/teamdetail/:id',
    pathname: '/dashboard/teamdetail',
    component: lazy(() => import('../components/Teamdetail/Teamdetail')),
    exact: true,
    public: false,
    private: true,
  },
  teamlist: {
    name: 'Team List',
    path: '/dashboard',
    component: lazy(() => import('../components/Teamlist/Teamlist')),
    exact: true,
    public: false,
    private: true,
  },
  userlist: {
    name: 'User List',
    path: '/dashboard/userlist',
    component: lazy(() => import('../components/Userlist/Userlist')),
    exact: true,
    public: false,
    private: true,
  },
  weekendlist: {
    name: 'Weekends',
    path: '/dashboard/weekendlist',
    component: lazy(() => import('../components/Weekendlist/Weekendlist')),
    exact: true,
    public: false,
    private: true,
  },
  createweekend: {
    name: 'Create Weekend',
    path: '/dashboard/create-weekend',
    component: lazy(() =>
      import('../components/Createweekenddates/Createweekenddates')
    ),
    exact: true,
    public: false,
    private: true,
  },
  createevent: {
    name: 'Create Event',
    path: '/dashboard/create-event',
    component: lazy(() => import('../components/Createevent/Createevent')),
    exact: true,
    public: false,
    private: true,
  },
  addconfirmedguests: {
    name: 'Add Confirmed Guests',
    path: '/dashboard/addconfirmed-guests',
    component: lazy(() =>
      import('../components/AddConfirmedGuests/AddConfirmedGuests')
    ),
    exact: true,
    public: false,
    private: true,
  },
  addeditteam: {
    name: 'Dashboard',
    path: '/dashboard/addeditteam',
    component: lazy(() => import('../components/Addeditteam/Addeditteam')),
    exact: true,
    public: false,
    private: true,
  },
  addeditteamschool: {
    name: 'Dashboard',
    path: '/dashboard/addeditteam/:id',
    pathname: '/dashboard/addeditteam',
    component: lazy(() => import('../components/Addeditteam/Addeditteam')),
    exact: true,
    public: false,
    private: true,
  },
  notifications: {
    name: 'Notifications',
    path: '/dashboard/notifications',
    component: lazy(() => import('../components/Notifications/Notifications')),
    exact: true,
    public: false,
    private: true,
  },
  // about: {
  // name: 'About',
  // path: '/about',
  //   component: lazy(() => import('../pages/About/About')),
  //   exact: true
  // },
  login: {
    name: 'Login',
    path: '/login',
    component: lazy(() => import('../pages/Loginpage/Loginpage')),
    exact: true,
    public: true,
    private: false,
  },
  signup: {
    name: 'Signup',
    path: '/signup',
    component: lazy(() => import('../pages/Signup/Signup')),
    exact: true,
    public: false,
    private: true,
  },
  Forgotpassword: {
    name: 'Forgot Password',
    path: '/forgotpassword',
    component: lazy(() =>
      import('../pages/Forgotpasswordpage/Forgotpasswordpage')
    ),
    exact: true,
    public: false,
    private: true,
  },
  Updateuser: {
    name: 'Update User',
    path: '/dashboard/updateuser/:id',
    pathname: '/dashboard/updateuser',
    component: lazy(() => import('../components/Edituser/Edituser')),
    exact: true,
    public: false,
    private: true,
  },
  Updateweekend: {
    name: 'Update Weekend',
    path: '/dashboard/update-weekend/:id',
    pathname: '/dashboard/update-weekend',
    component: lazy(() =>
      import('../components/Createweekenddates/Createweekenddates')
    ),
    exact: true,
    public: false,
    private: true,
  },
}

export const renderRoutes = Object.entries(routes)
