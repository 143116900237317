import React, { Component } from 'react'
import './Loader.scss'
import { PropagateLoader } from 'react-spinners'

class Loader extends Component {
  render() {
    return (
      <div className="custom-loader">
        <PropagateLoader size={30} color={'#3D8FF8'} />
      </div>
    )
  }
}

export default Loader
