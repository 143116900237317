import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { history, routes } from './../config/routes'

import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import { StaticRoute } from './StaticRoute'
import { AuthStore } from './../stores/AuthStore'

const Routes = (props) => {
  return (
    <Router history={history}>
      <Switch>
        {Object.keys(routes).map((key) => {
          const {
            component,
            name: pageName,
            exact,
            path,
            private: privateRoute,
            public: publicRoute,
            isStatic,
          } = routes[key]
          const props = {
            key,
            path,
            exact,
            component,
            private: privateRoute,
            public: publicRoute,
            isStatic,
            pageName,
            AuthStore,
          }
          if (privateRoute) return <PrivateRoute {...props} />
          else if (publicRoute) return <PublicRoute {...props} />
          else return <StaticRoute {...props} />
        })}
      </Switch>
    </Router>
  )
}

export default Routes
