import moment from 'moment'

export const LABELS = {
  HEADLINE: 'Preseason Planner',
}

export const defaultYear = '2025-2026'

export const ERROR = {
  emailRequired: 'Please input your Email Address!',
  tripRequired: 'Please input return trip',
  tourtypeRequired: 'Please input event type',
  schoolRequired: 'Please input school',
  roomRequired: 'Please input number of rooms',
  validEmailRequired: 'Please input valid Email!',
  cashRequired: 'Please input cash',
  eventRequired: 'Please input Event Name',
  participantsRequired: 'Please input Maximum Guest Teams',
  validParticipantsRequired: 'Please input Maximum Guest Teams in digits',
  guaranteeRequired: 'Please input guarantee',
  passwordRequired: 'Please input your password!',
  validPhoneRequired: 'Please input valid phone number',
  weekendRequired: 'Please input weekend name',
  startdateRequired: 'Please select start date',
  enddateRequired: 'Please select end date',
  validWeekendRequired: 'Please input valid weekend name',
  validFirstnameRequired: 'Please input valid first name',
  validLastnameRequired: 'Please input valid last name',
  validSchoolnameRequired: 'Please input valid school name',
  validAddressRequired: 'Please input valid address',
  validCitynameRequired: 'Please input valid city name',
  validZipcodeRequired: 'Please input valid zipcode',
  validGymnameRequired: 'Please input valid gym name',
  validEventRequired: 'Please input valid event name',
  validCashRequired: 'Please input cash in digits',
  validNumberRequired: 'Please input number greater than 0',
}

export const APP_CONSTANTS = {
  PAGE_SIZE: process.env.REACT_APP_PAGE_SIZE,
  PAGE: process.env.REACT_APP_PAGE,
}

export const LOGIN_URL_USER = process.env.REACT_APP_LOGIN_URL_USER
export const FRONT_USER = process.env.REACT_APP_FRONT_URL
export const SUPPORT_USER_ID = process.env.REACT_APP_SUPPORT_USER_ID

export const NumberRegex = /^[0-9]*$/
export const ZipCodeRegex = /^[0-9-]*$/
export const PhoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
export const NameRegexLegacy = /^[a-zA-Z ]*$/
export const NameRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const PasswordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]{6,}$/
export const weekendRegexLegacy = /^[^-\s][a-z A-Z\d@#$!%*?&]*$/
export const weekendRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const AddressRegexLegacy = /^[^-\s][a-z A-Z 0-9,-.]*$/
export const AddressRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const schoolRegex = /^[^-\s][a-z A-Z ,-]+$/
export const schoolRegexExtended = /^[^-\s][><?@+'`~^%&\*\[\]\{\}.!#|\\\"$';,:;=/\(\),\-\w\s+]*/
export const addweekendRegexLegacy = /^[^-\s][a-z A-Z 0-9]+$/
export const addweekendRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const firstnameRegexLegacy = /^[^-\s][a-z A-Z]+$/
export const firstnameRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const eventRegex = /^[a-zA-Z0-9\W][a-zA-Z0-9\W ]*$/
export const eventRegexLegacy = /^[^-\s][a-zA-Z0-9_/\s-]+$/
export const roomRegex = /^([0-9])[0-9]*$/
export const schoolGymRegexRegex = /^[^-\s][a-z A-Z\d@#$!%*?&_^()=+-~`]*$/

export const stateArr = [
  { key: 'Alabama', value: 'Alabama' },
  { key: 'Alaska', value: 'Alaska' },
  { key: 'Arizona', value: 'Arizona' },
  { key: 'Arkansas', value: 'Arkansas' },
  { key: 'California', value: 'California' },
  { key: 'Colorado', value: 'Colorado' },
  { key: 'Connecticut', value: 'Connecticut' },
  { key: 'Delaware', value: 'Delaware' },
  { key: 'District Of Columbia', value: 'District Of Columbia' },
  { key: 'Florida', value: 'Florida' },
  { key: 'Georgia', value: 'Georgia' },
  { key: 'Hawaii', value: 'Hawaii' },
  { key: 'Idaho', value: 'Idaho' },
  { key: 'Illinois', value: 'Illinois' },
  { key: 'Indiana', value: 'Indiana' },
  { key: 'Iowa', value: 'Iowa' },
  { key: 'Kansas', value: 'Kansas' },
  { key: 'Kentucky', value: 'Kentucky' },
  { key: 'Louisiana', value: 'Louisiana' },
  { key: 'Maine', value: 'Maine' },
  { key: 'Maryland', value: 'Maryland' },
  { key: 'Massachusetts', value: 'Massachusetts' },
  { key: 'Michigan', value: 'Michigan' },
  { key: 'Minnesota', value: 'Minnesota' },
  { key: 'Mississippi', value: 'Mississippi' },
  { key: 'Missouri', value: 'Missouri' },
  { key: 'Montana', value: 'Montana' },
  { key: 'Nebraska', value: 'Nebraska' },
  { key: 'Nevada', value: 'Nevada' },
  { key: 'New Hampshire', value: 'New Hampshire' },
  { key: 'New Jersey', value: 'New Jersey' },
  { key: 'New Mexico', value: 'New Mexico' },
  { key: 'New York', value: 'New York' },
  { key: 'North Carolina', value: 'North Carolina' },
  { key: 'North Dakota', value: 'North Dakota' },
  { key: 'Ohio', value: 'Ohio' },
  { key: 'Oklahoma', value: 'Oklahoma' },
  { key: 'Oregon', value: 'Oregon' },
  { key: 'Pennsylvania', value: 'Pennsylvania' },
  { key: 'Rhode Island', value: 'Rhode Island' },
  { key: 'South Carolina', value: 'South Carolina' },
  { key: 'South Dakota', value: 'South Dakota' },
  { key: 'Tennessee', value: 'Tennessee' },
  { key: 'Texas', value: 'Texas' },
  { key: 'Utah', value: 'Utah' },
  { key: 'Vermont', value: 'Vermont' },
  { key: 'Virginia', value: 'Virginia' },
  { key: 'Washington', value: 'Washington' },
  { key: 'West Virginia', value: 'West Virginia' },
  { key: 'Wisconsin', value: 'Wisconsin' },
  { key: 'Wyoming', value: 'Wyoming' },
]

export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day')
}

export const disabledPastEventDate = (current, date) => {
  return current && current < date.startOf('day')
}
