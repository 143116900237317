// require("dotenv").config();
let Configuration = {}
// console.log('process.env.API_ROUTE', process.env.REACT_APP_API_ROUTE)
// console.log('process.env.REACT_APP_NODE_ENV', process.env.REACT_APP_NODE_ENV)
switch (process.env.REACT_APP_NODE_ENV) {
  case 'test':
    Configuration = {
      ROUTE: process.env.REACT_APP_API_ROUTE,
    }
    break
  case 'production':
    Configuration = {
      ROUTE: process.env.REACT_APP_API_ROUTE,
    }
    break
  default:
    Configuration = {
      ROUTE: 'localhost:4000/api/v1',
    }
}
module.exports = { Configuration }
